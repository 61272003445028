@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'theme-variables.css';
@import 'base.css';
@import 'components.css';
@import 'actions.css';
@import 'responsive.css';
@import 'layout-fixes.css';
@import 'scaling.css'; /* Add this line */