:root {
  /* Base Sei colors */
  --sei-navy: #001B2A;
  --sei-navy-lighter: #002a3f;
  --sei-navy-lightest: #003a59;
  --sei-crimson: #9E1F19;
  --sei-crimson-soft: rgba(158, 31, 25, 0.8);
  --sei-crimson-subtle: rgba(158, 31, 25, 0.3);
  --sei-crimson-transparent: rgba(158, 31, 25, 0.1);
  --sei-maroon: #780000;
  --sei-light: #ECEDEE;
  --sei-gray: #8CABA9;
  --sei-gray-dark: #6A8280;
  --sei-gray-light: #A8C2C0;
  --sei-gray-subtle: rgba(140, 171, 169, 0.2);
  
  /* Dark theme - with gradients and varying opacities */
  --background: radial-gradient(circle at center, var(--sei-navy-lighter) 0%, var(--sei-navy) 100%);
  --container-bg: linear-gradient(135deg, rgba(0, 42, 63, 0.7) 0%, rgba(0, 27, 42, 0.9) 100%);
  --container-border: rgba(158, 31, 25, 0.4);
  --active-tab-bg: linear-gradient(135deg, var(--sei-crimson-soft) 0%, var(--sei-maroon) 100%);
  --hover-bg: rgba(158, 31, 25, 0.15);
  --text-primary: var(--sei-light);
  --text-secondary: rgba(236, 237, 238, 0.7);
  --accent: var(--sei-crimson);
  --accent-hover: var(--sei-maroon);
  --accent-transparent: var(--sei-crimson-transparent);
  --button-bg: transparent;
  --button-border: rgba(158, 31, 25, 0.6);
  --grid-bg: rgba(0, 27, 42, 0.7);
  --item-border: rgba(140, 171, 169, 0.3);
  --item-border-hover: var(--sei-crimson-soft);
  --active-border: var(--sei-crimson);
  --input-bg: rgba(0, 27, 42, 0.5);
  --input-border:  rgba(158, 31, 25, 0.6);
  --shadow-color: rgba(0, 0, 0, 0.2);
  --card-shadow: 0 4px 12px var(--shadow-color);
  --trait-item-bg: rgba(0, 27, 42, 0.5);
}

body.light-theme {
  /* Light theme - with gradients and varying opacities */
  --background: linear-gradient(135deg, #F5F5F7 0%, var(--sei-light) 100%);
  --container-bg: rgba(255, 255, 255, 0.85);
  --container-border: rgba(158, 31, 25, 0.3);
  --active-tab-bg: linear-gradient(135deg, var(--sei-crimson) 0%, var(--sei-maroon) 100%);
  --hover-bg: rgba(158, 31, 25, 0.1);
  --text-primary: var(--sei-navy);
  --text-secondary: rgba(0, 27, 42, 0.7);
  --accent: var(--sei-crimson);
  --accent-hover: var(--sei-maroon);
  --accent-transparent: rgba(158, 31, 25, 0.08);
  --button-bg: rgba(255, 255, 255, 0.8);
  --button-border: rgba(158, 31, 25, 0.4);
  --grid-bg: rgba(236, 237, 238, 0.5);
  --item-border: rgba(140, 171, 169, 0.4);
  --item-border-hover: var(--sei-crimson-soft);
  --active-border: var(--sei-crimson);
  --input-bg: rgba(255, 255, 255, 0.7);
  --input-border: rgba(158, 31, 25, 0.4);
  --shadow-color: rgba(0, 0, 0, 0.08);
  --card-shadow: 0 4px 15px var(--shadow-color);
  --trait-item-bg: rgba(236, 237, 238, 0.5);
}