/* scaling.css - Makes all elements 75% of their original size */

/* Global scaling */
html {
  font-size: 75%; /* Reduce the base font size to 75% */
}

/* Main containers */
.App {
  padding: 15px; /* 75% of 20px */
  padding-bottom: 56px; /* 75% of 75px */
}

.container {
  gap: 11px; /* 75% of 15px */
}

.main-content {
  gap: 11px; /* 75% of 15px */
}

/* Header & Footer */
.header {
  font-size: 1.5em; /* 75% of 2em */
  margin-bottom: 15px; /* 75% of 20px */
}

.sei-wave {
  height: 18px; /* 75% of 24px */
  width: 18px; /* 75% of 24px */
}

.theme-toggle {
  font-size: 0.9em; /* 75% of 1.2em */
  top: 15px; /* 75% of 20px */
  right: 15px; /* 75% of 20px */
}

.footer {
  padding: 11px 0; /* 75% of 15px 0 */
}

/* Buttons */
button {
  padding: 6px 12px; /* 75% of 8px 16px */
  gap: 6px; /* 75% of 8px */
  border-radius: 4.5px; /* 75% of 6px */
}

.action-button {
  padding: 6px 11px; /* 75% of 8px 15px */
  border-radius: 4.5px; /* 75% of 6px */
  gap: 6px; /* 75% of 8px */
  min-width: 75px; /* 75% of 100px */
}

.tab-button {
  min-width: 82px; /* 75% of 110px */
  height: 28px; /* 75% of 38px */
  padding: 0 9px; /* 75% of 0 12px */
  border-radius: 4.5px; /* 75% of 6px */
  font-size: 0.9em; /* 75% of 0.95em */
}

.secondary-tabs .tab-button {
  min-width: 75px; /* 75% of 100px */
  font-size: 0.9rem; /* 75% of 0.7rem */
}

/* Trait selection */
.trait-selection, .art-display-container {
  border-radius: 9px; /* 75% of 12px */
  padding: 15px; /* 75% of 20px */
}

.trait-selection {
  padding-top: 30px; /* 75% of 40px */
}

.info-button {
  top: 11px; /* 75% of 15px */
  right: 11px; /* 75% of 15px */
  font-size: 0.9rem; /* 75% of 1.2rem */
  width: 22px; /* 75% of 30px */
  height: 22px; /* 75% of 30px */
}

.tabs {
  gap: 7px; /* 75% of 10px */
  margin-bottom: 11px; /* 75% of 15px */
}

.traits-grid {
  gap: 7px; /* 75% of 10px */
  padding: 7px; /* 75% of 10px */
  height: 375px; /* 75% of 500px */
  border-radius: 7px; /* 75% of 10px */
}

.trait {
  border-radius: 6px; /* 75% of 8px */
}

/* Canvas & Art Display */
canvas.art-display {
  width: 188px; /* 75% of 250px */
  height: 188px; /* 75% of 250px */
  margin: 0 auto 15px; /* 75% of 0 auto 20px */
  border-radius: 7px; /* 75% of 10px */
}

.match-message {
  min-height: 45px; /* 75% of 60px */
  margin: 7px 0 15px; /* 75% of 10px 0 20px */
}

.selected-traits {
  border-radius: 7px; /* 75% of 10px */
  padding: 11px; /* 75% of 15px */
}

.selected-traits h3 {
  font-size: 1.5rem; /* 75% of 1.1rem */
  margin-bottom: 9px; /* 75% of 12px */
}

.traits-list {
  gap: 4px; /* 75% of 6px */
}

.trait-item {
  padding: 6px 9px; /* 75% of 8px 12px */
  border-radius: 4.5px; /* 75% of 6px */
  font-size: 0.9rem; /* 75% of 0.9rem */
}

.trait-item strong {
  margin-right: 6px; /* 75% of 8px */
}

/* ID Search */
.id-search {
  gap: 6px; /* 75% of 8px */
  padding: 0 7px; /* 75% of 0 10px */
}

.id-search input {
  padding: 6px 9px; /* 75% of 8px 12px */
  border-radius: 4.5px; /* 75% of 6px */
  width: 52px; /* 75% of 70px */
}

.id-search input:focus {
  width: 67px; /* 75% of 90px */
}

/* Actions container */
.actions-container {
  padding: 9px; /* 75% of 12px */
  border-radius: 9px; /* 75% of 12px */
  margin-bottom: 11px; /* 75% of 15px */
}

.actions-row {
  gap: 7px; /* 75% of 10px */
  padding: 4px 0; /* 75% of 5px 0 */
}

/* Rules modal */
.rules-modal {
  padding: 19px; /* 75% of 25px */
  border-radius: 9px; /* 75% of 12px */
  max-width: 375px; /* 75% of 500px */
}

.rules-modal h2 {
  margin-bottom: 15px; /* 75% of 20px */
}

.rules-modal p {
  margin-bottom: 15px; /* 75% of 20px */
  line-height: 1.1; /* 75% of 1.5 */
}

.rules-modal button {
  margin-top: 11px; /* 75% of 15px */
  padding: 7px 15px; /* 75% of 10px 20px */
}

/* Trait display components */
.trait-content img {
  max-width: 67%; /* 75% of 90% */
  max-height: 67%; /* 75% of 90% */
}

.pallet-button, .curated-button {
  padding: 4px 9px; /* 75% of 6px 12px */
  margin-top: 4px; /* 75% of 5px */
  border-radius: 4.5px; /* 75% of 6px */
  font-size: 1.5em; /* 75% of 0.85em */
}

/* Media Queries - adjusted for 75% scale */
@media (max-width: 768px) {
  .traits-grid {
    grid-template-columns: repeat(3, 1fr);
    height: 300px !important; /* 75% of 400px */
    gap: 6px; /* 75% of 8px */
  }
  
  .tab-button {
    padding: 1.5px 3px; /* 75% of 2px 4px */
    font-size: 0.48rem; /* 75% of 0.65rem */
    height: 21px; /* 75% of 28px */
  }
  
  .secondary-tabs .tab-button {
    min-width: 60px; /* 75% of 80px */
    font-size: 0.48rem; /* 75% of 0.65rem */
  }
  
  canvas.art-display {
    width: 165px; /* 75% of 220px */
    height: 165px; /* 75% of 220px */
  }
  
  .trait-selection {
    padding: 7px; /* 75% of 10px */
    padding-top: 22px; /* 75% of 30px */
  }
}

@media (max-width: 600px) {
  .tab-button {
    font-size: 0.45rem; /* 75% of 0.6rem */
    padding: 1.5px; /* 75% of 2px */
    height: 19px; /* 75% of 26px */
  }
  
  .secondary-tabs .tab-button {
    min-width: 52px; /* 75% of 70px */
  }
}

@media (max-width: 480px) {
  .traits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4.5px; /* 75% of 6px */
  }
  
  .tab-button {
    font-size: 0.33rem; /* 75% of 0.45rem */
    padding: 0.75px; /* 75% of 1px */
    height: 18px; /* 75% of 24px */
  }
  
  .tab-button span {
    font-size: 0.33rem; /* 75% of 0.45rem */
  }
 
  .secondary-tabs .tab-button {
    min-width: 49px; /* 75% of 65px */
  }
  
  .trait-item {
    font-size: 0.52rem; /* 75% of 0.7rem */
  }
}

/* Additional adjustments for mobile.css */
@media (max-width: 768px) {
  .trait-selection, .art-display-container {
    max-width: 337px; /* 75% of 450px */
  }
  
  .traits-grid {
    height: 262px !important; /* 75% of 350px */
  }
  
  .bottom-card {
    max-width: 337px; /* 75% of 450px */
    padding: 11px !important; /* 75% of 15px */
  }
  
  canvas.art-display {
    width: 225px !important; /* 75% of 300px */
    height: 225px !important; /* 75% of 300px */
  }
  
  .token-input input {
    width: 60px !important; /* 75% of 80px */
  }
}

@media (max-width: 375px) {
  canvas.art-display {
    width: 187px !important; /* 75% of 250px */
    height: 187px !important; /* 75% of 250px */
  }
}

/* Main content minimum height adjustment */
.main-content {
  min-height: 450px; /* 75% of 600px */
}

/* Make sure scrollbar is thinner */
.traits-grid::-webkit-scrollbar {
  width: 6px; /* 75% of 8px */
}

.traits-grid::-webkit-scrollbar-thumb {
  border-radius: 4.5px; /* 75% of 6px */
  border: 2px solid transparent; /* 75% of 3px */
}