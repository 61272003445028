/* Layout fixes for overlapping, scrollbars and trait display */

/* Fix traits grid scrollbar */
.traits-grid {
  scrollbar-width: thin;
  scrollbar-color: var(--item-border) transparent;
}

.traits-grid::-webkit-scrollbar {
  width: 8px;
}

.traits-grid::-webkit-scrollbar-track {
  background: transparent;
}

.traits-grid::-webkit-scrollbar-thumb {
  background-color: var(--item-border);
  border-radius: 6px;
  border: 3px solid transparent;
}

/* Improve selected traits display */
.selected-traits {
  margin-top: 25px;
  text-align: center;
  color: var(--text-primary);
  width: 100%;
  max-height: none; /* Remove height restriction */
  overflow: visible; /* Show all content */
  background: var(--trait-item-bg);
  border-radius: 10px;
  padding: 12px;
  border: 1px solid var(--item-border);
}

.traits-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Single column for clearer display */
  gap: 8px;
  width: 100%;
}

.trait-item {
  text-align: left;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Remove max-height constraint on containers to avoid cut-offs */
.trait-selection, .art-display-container {
  min-height: auto;
  max-height: none;
}

/* Add more space for the art display container */
.art-display-container {
  padding-bottom: 30px;
}

/* Better spacing for trait items */
.art-display-container .traits-list {
  margin-top: 15px;
}

/* Fix for overlapping trait images */
.trait {
  position: relative;
  overflow: hidden;
}

.trait img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Make sure the main containers have enough space */
.main-content {
  min-height: 600px;
}