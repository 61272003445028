.actions-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--container-bg);
  padding: 12px;
  border-radius: 12px;
  margin: 0;
  box-shadow: var(--card-shadow);
  transition: background 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--container-border);
  backdrop-filter: blur(5px);
  order: 1;
  margin-bottom: 15px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.actions-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
}

.top-row {
  flex-wrap: wrap;
}

.bottom-row {
  width: 100%;
}

.gm-tweet-button {
  width: 100%;
}

.action-button {
  background: var(--button-bg);
  color: var(--text-primary);
  border: 1px solid var(--button-border);
  padding: 8px 15px;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 100px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.action-button:hover {
  background: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.action-button.primary {
  background: var(--active-tab-bg);
  color: white;
  border: none;
}

.action-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}



.id-search {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--button-bg);
  border: 1px solid var(--button-border);
  border-radius: 6px;
  padding: 0 8px;
  min-width: 100px; /* Keep min-width to match other buttons */
  width: min-content; /* Set width to content size */
  max-width: 100px; /* Prevent expanding beyond 100px */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease; /* Specify only what should transition */
}

.id-search:hover {
  background: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.search-icon {
  display: flex;
  margin-right: 10px;
  color: var(--text-primary);
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.id-search input {
  background: transparent;
  border: none;
  color: var(--text-primary);
  padding: 8px 4px;
  font-size: 1em;
  text-align: center;
  width: 60px; /* Fixed width */
  min-width: 60px; /* Ensure minimum width */
  max-width: 60px; /* Maximum width */
  box-sizing: border-box;
  transition: none; /* Disable transitions on the input */
  font-weight: 500;
}

.id-search input::placeholder {
  padding-left: 3px;
}

.id-search input:focus {
  outline: none;
  width: 60px; /* Keep width consistent when focused */
}

.id-search:focus-within {
  border-color: var(--accent);
  box-shadow: 0 0 0 2px var(--accent-transparent);
  width: min-content; /* Keep width based on content */
  max-width: 100px; /* Maintain max width when focused */
}

