.trait-selection, .art-display-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--container-bg);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  width: 100%;
  position: relative;
  border: 1px solid var(--container-border);
  box-shadow: var(--card-shadow);
  height: auto;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.primary-tabs {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
  gap: 5px;
  flex-wrap: nowrap;
}

.primary-tabs .tab-button {
  flex: 1 1 0%;
  min-width: 0;
}

.secondary-tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.secondary-tabs .tab-button {
  min-width: 100px;
  flex-grow: 0;
  font-size: 0.7rem;
}

.tab-button {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 4px;
  background: var(--button-bg);
  color: var(--text-primary);
  border: 1px solid var(--button-border);
  border-radius: 6px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-tab {
  background: var(--active-tab-bg);
  border-color: var(--active-border);
  color: white;
}

.traits-grid::before {
  content: '';
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.traits-grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.traits-grid::-webkit-scrollbar {
  width: 8px;
}

.traits-grid::-webkit-scrollbar-track {
  background: transparent;
}

.traits-grid::-webkit-scrollbar-thumb {
  background-color: var(--item-border);
  border-radius: 6px;
}

.trait {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border: 1px solid var(--item-border);
  border-radius: 6px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.trait-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trait img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.trait.selected {
  border-color: var(--active-border);
  border-width: 2px;
}

.conflict-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.conflict-icon {
  position: absolute;
  top: 5px;
  font-size: 1rem;
}

canvas.art-display {
  width: 260px;
  height: 260px;
  display: block;
  margin: 0 auto 5px;
  border-radius: 10px;
  border: 1px solid var(--container-border);
}

.match-message {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0 10px;
}

.token-id {
  margin-bottom: 8px;
  color: var(--accent);
  font-weight: bold;
  font-size: 1.5rem;
}

/* Collapsible traits panel styling */
.selected-traits-container {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--trait-item-bg);
  border: 1px solid var(--item-border);
}

.traits-toggle-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(255, 0, 0, 0.2);
  border: none;
  color: var(--accent);
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease;
  font-size: 1rem;
}

.traits-toggle-button:hover {
  background-color: rgba(255, 0, 0, 0.3);
}

.traits-toggle-button .toggle-icon {
  font-size: 0.75rem;
  transition: transform 0.3s ease;
}

.traits-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.traits-panel.open {
  max-height: 500px; /* Adjust based on your content */
}

.traits-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  width: 100%;
  padding: 10px;
}

.trait-item {
  background: rgba(0, 0, 0, 0.1);
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.trait-item strong {
  color: var(--accent);
  margin-right: 8px;
}

.pallet-button, .curated-button {
  display: inline-block;
  background: var(--active-tab-bg);
  color: white;
  padding: 6px 12px;
  margin-top: 5px;
  border-radius: 6px;
  text-decoration: none;
  font-size: 0.85em;
  cursor: pointer;
  border: none;
}

@media (max-width: 768px) {
  .traits-grid {
    grid-template-columns: repeat(3, 1fr);
    height: 400px;
    gap: 8px;
  }
  
  .primary-tabs {
    gap: 2px;
  }
  
  .tab-button {
    padding: 2px 4px;
    font-size: 0.65rem;
    height: 28px;
  }
  
  .secondary-tabs .tab-button {
    min-width: 80px;
    font-size: 0.65rem;
  }
  
  canvas.art-display {
    width: 220px;
    height: 220px;
  }
  
  .main-content {
    flex-direction: column;
  }
  
  .trait-selection {
    min-height: auto;
    padding: 10px;
    padding-top: 30px;
  }
  
  /* Mobile-specific traits panel adjustments */
  .selected-traits-container {
    position: relative;
    z-index: 10;
  }
  
  .traits-toggle-button {
    padding: 8px;
    font-size: 0.85rem;
  }
  
  .traits-panel {
    transition: max-height 0.25s ease-out;
  }
  
  /* Start with panel closed on mobile */
  .traits-panel {
    max-height: 0;
  }
}

@media (max-width: 600px) {
  .tab-button {
    font-size: 0.6rem;
    padding: 2px;
    height: 26px;
  }
  
  .secondary-tabs .tab-button {
    min-width: 70px;
  }
  
  .primary-tabs {
    gap: 1px;
  }
}

@media (max-width: 480px) {
  .traits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }
  
  .primary-tabs, .secondary-tabs {
    gap: 1px;
  }
  
  .tab-button {
    font-size: 0.45rem;
    padding: 1px;
    height: 24px;
    border-width: 1px;
  }
  
  .tab-button span {
    font-size: 0.45rem;
  }
 
  .secondary-tabs .tab-button {
    min-width: 65px;
  }
  
  .trait-item {
    font-size: 0.7rem;
  }
}