/* App.css - Core layout styles only */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  background: var(--background);
  color: var(--text-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  position: relative;
  padding-bottom: 75px;
  transition: background 0.3s ease, color 0.3s ease;
}

.header {
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header a {
  color: var(--accent);
  text-decoration: none;
  transition: color 0.3s ease;
}

.header a:hover {
  color: var(--accent-hover);
}

.theme-toggle {
  background: transparent;
  border: none;
  color: var(--text-primary);
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Container structure */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.main-content {
  display: flex;
  align-items: stretch;
  gap: 15px;
  width: 100%;
  order: 2;
}

/* Footer */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--container-bg);
  color: var(--text-primary);
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid var(--container-border);
  backdrop-filter: blur(8px);
  z-index: 10;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
}

/* Modal overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Base button styles */
button {
  background-color: var(--button-bg);
  color: var(--text-primary);
  border: 1px solid var(--button-border);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
  font-family: inherit;
  font-weight: 500;
}

button:hover {
  background-color: var(--hover-bg);
  transform: translateY(-1px);
}
.info-button {
  position: absolute;
  top: 5px;  /* Move up by 10 pixels */
  right: 10px; /* Move right by 10 pixels */
}
