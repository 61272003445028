/* Mobile.css with Sei Theme */
@import './theme-variables.css';

/* Mobile-specific styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 10px;
  }

  .trait-selection, .art-display-container {
    width: 90%;
    max-width: 450px;
    margin: 5px auto;
    padding: 15px;
  }
  
  .traits-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    height: 350px !important;
  }
  
  .traits-grid .trait {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1/1;
  }

  .bottom-card {
    width: 90% !important;
    max-width: 450px;
    margin: 10px auto !important;
    padding: 15px !important;
  }

  .buttons {
    flex-wrap: wrap !important;
    gap: 8px !important;
  }

  button {
    padding: 8px 12px !important;
    font-size: 0.9em !important;
  }
  
  .token-input {
    width: 100% !important;
    margin: 8px 0 !important;
    justify-content: center !important;
  }
  
  .token-input input {
    width: 80px !important;
  }
  
  canvas.art-display {
    width: 300px !important;
    height: 300px !important;
  }
  
  .footer {
    padding: 8px 0;
  }
  
  .links-container {
    flex-wrap: wrap;
  }
  
  .links-container p {
    margin: 5px;
  }
  
  .btn-link {
    font-size: 0.9em;
  }
  
  .tabs {
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .tab-button {
    margin: 2px;
    padding: 6px 10px;
    font-size: 0.9em;
  }
  
  .header {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .match-message {
    height: auto;
    margin: 5px 0;
  }
  
  .selected-traits {
    height: auto;
    max-height: 200px;
  }
  
  .traits-list {
    grid-template-columns: 1fr;
  }
  
  .theme-toggle {
    top: 10px;
    right: 10px;
  }
}

/* Small phones */
@media (max-width: 375px) {
  .traits-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  
  .buttons button {
    font-size: 0.8em !important;
    padding: 6px 10px !important;
  }
  
  canvas.art-display {
    width: 250px !important;
    height: 250px !important;
  }
}