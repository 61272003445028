@import 'theme-variables.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  background: var(--background);
  color: var(--text-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  position: relative;
  padding-bottom: 100px;
  transition: background 0.3s ease, color 0.3s ease;
}

.header {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.header a {
  color: var(--accent);
  text-decoration: none;
  transition: color 0.3s ease;
}

.header a:hover {
  color: var(--accent-hover);
}

.sei-wave {
  margin-right: 10px;
  color: var(--accent);
  height: 24px;
  width: 24px;
}

.theme-toggle {
  background: transparent;
  border: none;
  color: var(--text-primary);
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 1200px;
  width: 100%;
}

.main-content {
  display: flex;
  align-items: stretch;
  gap: 15px;
  width: 100%;
  order: 2;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--container-bg);
  color: var(--text-primary);
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid var(--container-border);
  backdrop-filter: blur(8px);
  z-index: 10;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.rules-modal {
  background: var(--container-bg);
  padding: 25px;
  border-radius: 12px;
  max-width: 500px;
  width: 85%;
  text-align: left;
  color: var(--text-primary);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--container-border);
  backdrop-filter: blur(10px);
  max-height: 80vh;
  overflow-y: auto;
}

.rules-modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--accent);
  font-weight: 600;
}

.rules-modal p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.rules-modal button {
  margin-top: 15px;
  background: var(--active-tab-bg);
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
}

.rules-modal button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

button {
  background-color: var(--button-bg);
  color: var(--text-primary);
  border: 1px solid var(--button-border);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  font-family: inherit;
  font-weight: 500;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}