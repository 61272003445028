.trait-selection, .art-display-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--container-bg);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  position: relative;
  border: 1px solid var(--container-border);
  box-shadow: var(--card-shadow);
  min-height: auto; /* Let content determine height */
  height: auto;
}

.trait-selection {
  padding-top: 40px;
}

.info-button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
  color: var(--accent);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.tab-button {
  min-width: 110px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0 12px;
  background: var(--button-bg);
  color: var(--text-primary);
  border: 1px solid var(--button-border);
  border-radius: 6px;
  font-size: 0.95em;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.active-tab {
  background: var(--active-tab-bg);
  border-color: var(--active-border);
  color: white;
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;
  padding: 10px;
  height: 500px;
  overflow-y: auto;
  background: var(--grid-bg);
  border-radius: 10px;
  border: 1px solid var(--item-border);
}

.trait {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  border: 1px solid var(--item-border);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

canvas.art-display {
  width: 250;
  height: 250px;
  display: block;
  margin: 0 auto 20px;
  border-radius: 10px;
  border: 1px solid var(--container-border);
}

.match-message {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
}

.selected-traits {
  width: 100%;
  background: var(--trait-item-bg);
  border-radius: 10px;
  padding: 15px;
  border: 1px solid var(--item-border);
  margin-top: auto;
}

.selected-traits h3 {
  text-align: center;
  font-size: 1.1rem;
  color: var(--accent);
  margin-bottom: 12px;
}

.traits-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
  width: 100%;
}

.trait-item {
  background: rgba(0, 0, 0, 0.1);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.trait-item strong {
  color: var(--accent);
  margin-right: 8px;
}