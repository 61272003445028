/* Footer.css with Sei Theme */
@import '../theme-variables.css';

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--container);
  color: var(--text);
  text-align: center;
  padding: 10px 0;
  margin: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}

.btn-link {
  color: var(--text);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-align: center;
  transition: color 0.3s ease;
}

.btn-link:hover {
  color: var(--accent);
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  transition: filter 0.3s ease;
}

.btn-link:hover .social-icon {
  filter: saturate(1.5) brightness(0.9);
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
  .footer {
    padding: 8px 0;
  }
  
  .links-container {
    justify-content: center;
    gap: 15px;
  }
  
  .btn-link {
    margin: 0 5px;
  }
  
  .link-text {
    font-size: 0.9em;
  }
}

/* Media query for mobile phones */
@media (max-width: 576px) {
  .footer {
    padding: 5px 0;
  }
  
  .links-container {
    gap: 10px;
  }
  
  .btn-link {
    flex-direction: column;
    margin: 0 8px;
  }
  
  .link-text {
    display: none;
  }
  
  .social-icon {
    margin-right: 0;
    width: 20px;
    height: 20px;
  }
}